.demo-header {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  padding-top: 5rem;
}

.demo-content p, .demo-content a {
  text-align: center;
  display: block;
}

.demo-content a {
  color: #007bff;
  text-decoration: none;
}

.demo-content a:hover {
  text-decoration: underline;
}