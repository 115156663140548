.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #5a58c1;
}

.nav-item {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 1rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 6.25rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.nav-item:hover {
  background-color: black;
  color: white;
  border-radius: 3rem;
  transition: ease-in-out 0.3s;
}
