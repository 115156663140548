.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(24.333% - 1rem); 
  height: auto; 
  background-color: #f0f0f0;
  border: 1px solid black;
  border-radius: 1rem;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem; 
  margin: 1rem;  
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.card:hover {
  transform: scale(1.05);
  transition: ease-in-out 0.3s;
}