.about-header {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  padding-top: 5rem;
  margin-bottom: 2rem;
}

.team-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 2rem;
}

/* Add additional styling for Card component to match the design */
.card {
  background: #fff;
  border-radius: 8px;
  width: 300px; /* Adjust based on your layout */
  text-align: center;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card img {
  border-radius: 50%;
  width: 200px; /* Adjust to fit your design */
  height: 200px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.card h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.card h3 {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 1rem;
}

.card p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.card a {
  font-size: 0.9rem;
  color: #1a0dab;
  text-decoration: none;
}
